
.nav-bar{
    height: 7vh;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2%;
    background: none;
    z-index: 10;
    color: white;
    .logo {
        object-fit: contain;
        height: 4vh;
    }
    .nav-links {
        display: flex;
        gap: 16px;
        margin: 0 1%;
        a{
            height: fit-content;
            font-size: 1.3rem;
            font-weight: 500;
            color: currentColor;
        } 
    }
    .dark a{
        color: black;
    }
};
@media screen and (max-width: 600px) { 
    .nav-links a{
        font-size: 1.1rem !important;
    }
}

