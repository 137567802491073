
.home-body{
    min-height: 100vh;
    height: fit-content;
    background-color: black;
    
}
header{
   width: 100%;
   height: calc(100vh - 120px); 
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
}
.home-first{
    width: 100%;
    height: 100%;
    padding: 7vh 0;;
    background-color: rgba(0, 0, 0, .4);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 5;
    
}
.home-logo{
    width: 90%;
    max-height: 80%;
    max-width: 800px;
    object-fit: contain;

    
    
}
.audio-player {
    width: 800px !important;
    max-width: 95%;
    margin-top: 2%;
    padding: 1% 2%;
    gap: 5px;
    filter: invert(100%);
    font-size: 1.2rem !important;
    border: 2px solid;
   
    img{
        height: 2rem !important;
    }
    .audio-player-track-name{
        border-radius: 5px;
        background-color: rgba(198, 198, 198, 0.5);
        padding: 5px

    }
}

.swiper {
    height: inherit;
    z-index: 0;
}
.slide-photo {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
}

.home-info{
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-bottom: 4vh; 
}
.social{
    display: flex;
    height: 120px;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: white;
    gap: 32px;
    img{
        height: 48px;
        width: auto;
    }
}
.prox-conciertos{
    background-color: white;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 5vh;
    .concierto-header{
        margin: 1% auto;
        width: 90%;
        max-width: 450px;
        h2{
            margin-bottom: 8px;
        }
    }
    p{
        margin: 1% auto;
        width: 90%;
        max-width: 450px;
        font-style: italic;
        }
    .concierto{
        display: flex;
        width: 90%;
        max-width: 450px;
        height: fit-content;
        margin: 1% auto;
        gap: 3%;
        margin-bottom: 1%;
        .fecha-container{
            display: inline-block;
            border: 1px solid black;
            padding: 8px 8px;
            text-align: center ;
            font-weight: 300;
            line-height: 24px;
            width: 96px;
            .fecha{
                font-size: 1.1rem;
                font-weight: 500;
                
            }
        }
        .locacion{
            width: 80%;
            .recinto{
                height: fit-content;
                display:block;
                margin: 8px 0;
                font-weight: 500;
            }
            .ciudad{
                height: fit-content;
                display: inline;
                font-weight: 300;      
              }

        }
        
    }
}


.prensa{
    background-color: white;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 16px;
    padding-bottom: 5vh;
    
    h2{
        margin-bottom: 8px;
        width: 90%;
        max-width: 600px;
    }
    li{
        margin: 16px 0;
        list-style-position: inside;
        
        a{
        width: 90%;
        max-width: 600px;
        line-height: 24px;
        
        text-decoration: underline !important;
        }
    }
}

@media (max-width: 600px) { 
    .home-info{
        flex-direction: column;
        .prox-conciertos, .prensa{
            width: 100%;
        }
    }
    .social{
        gap: 24px;
        img{
            height: 40px;
        }
    }    

}