
.bio-body{
    width: fit-content;
    max-width: 900px;
    height: fit-content;
    margin: 0 auto;
    padding: 8vh 3%;
    h1{
        margin: 3% 2%;
    }

    img{
        max-width: 60%;
        object-fit: contain;
        padding-right: 1%;
        float: left;
        margin: 1%;
    }
    p{
        font-size: 1.15rem;
        margin: 2% 0;
        text-align: justify;
        line-height: 1.5rem;
    }        
}

@media screen and (max-width: 600px) { 

    .bio-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        h1{
            margin: 3% auto 3% 0;
        }
        img{
        float: none;
        max-width: 90%;
        }
    }
}