@font-face {
  font-family: 'Manrope';
  src: url("../fonts/Manrope-ExtraLight.woff2") format("woff2"), url("../fonts/Manrope-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url("../fonts/Manrope-Light.woff2") format("woff2"), url("../fonts/Manrope-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url("../fonts/Manrope-Bold.woff2") format("woff2"), url("../fonts/Manrope-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url("../fonts/Manrope-ExtraBold.woff2") format("woff2"), url("../fonts/Manrope-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url("../fonts/Manrope-ExtraLight.woff2") format("woff2"), url("../fonts/Manrope-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url("../fonts/Manrope-SemiBold.woff2") format("woff2"), url("../fonts/Manrope-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url("../fonts/Manrope-Medium.woff2") format("woff2"), url("../fonts/Manrope-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url("../fonts/Manrope-Regular.woff2") format("woff2"), url("../fonts/Manrope-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.nav-bar {
  height: 7vh;
  width: 100%;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 2%;
  background: none;
  z-index: 10;
  color: white;
}

.nav-bar .logo {
  -o-object-fit: contain;
     object-fit: contain;
  height: 4vh;
}

.nav-bar .nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
  margin: 0 1%;
}

.nav-bar .nav-links a {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 1.3rem;
  font-weight: 500;
  color: currentColor;
}

.nav-bar .dark a {
  color: black;
}

@media screen and (max-width: 600px) {
  .nav-links a {
    font-size: 1.1rem !important;
  }
}

.home-body {
  min-height: 100vh;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: black;
}

header {
  width: 100%;
  height: calc(100vh - 120px);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.home-first {
  width: 100%;
  height: 100%;
  padding: 7vh 0;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  z-index: 5;
}

.home-logo {
  width: 90%;
  max-height: 80%;
  max-width: 800px;
  -o-object-fit: contain;
     object-fit: contain;
}

.audio-player {
  width: 800px !important;
  max-width: 95%;
  margin-top: 2%;
  padding: 1% 2%;
  gap: 5px;
  -webkit-filter: invert(100%);
          filter: invert(100%);
  font-size: 1.2rem !important;
  border: 2px solid;
}

.audio-player img {
  height: 2rem !important;
}

.audio-player .audio-player-track-name {
  border-radius: 5px;
  background-color: rgba(198, 198, 198, 0.5);
  padding: 5px;
}

.swiper {
  height: inherit;
  z-index: 0;
}

.slide-photo {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
}

.home-info {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-bottom: 4vh;
}

.social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 120px;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  gap: 32px;
}

.social img {
  height: 48px;
  width: auto;
}

.prox-conciertos {
  background-color: white;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding-bottom: 5vh;
}

.prox-conciertos .concierto-header {
  margin: 1% auto;
  width: 90%;
  max-width: 450px;
}

.prox-conciertos .concierto-header h2 {
  margin-bottom: 8px;
}

.prox-conciertos p {
  margin: 1% auto;
  width: 90%;
  max-width: 450px;
  font-style: italic;
}

.prox-conciertos .concierto {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 450px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 1% auto;
  gap: 3%;
  margin-bottom: 1%;
}

.prox-conciertos .concierto .fecha-container {
  display: inline-block;
  border: 1px solid black;
  padding: 8px 8px;
  text-align: center;
  font-weight: 300;
  line-height: 24px;
  width: 96px;
}

.prox-conciertos .concierto .fecha-container .fecha {
  font-size: 1.1rem;
  font-weight: 500;
}

.prox-conciertos .concierto .locacion {
  width: 80%;
}

.prox-conciertos .concierto .locacion .recinto {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: block;
  margin: 8px 0;
  font-weight: 500;
}

.prox-conciertos .concierto .locacion .ciudad {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: inline;
  font-weight: 300;
}

.prensa {
  background-color: white;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 0 16px;
  padding-bottom: 5vh;
}

.prensa h2 {
  margin-bottom: 8px;
  width: 90%;
  max-width: 600px;
}

.prensa li {
  margin: 16px 0;
  list-style-position: inside;
}

.prensa li a {
  width: 90%;
  max-width: 600px;
  line-height: 24px;
  text-decoration: underline !important;
}

@media (max-width: 600px) {
  .home-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .home-info .prox-conciertos, .home-info .prensa {
    width: 100%;
  }
  .social {
    gap: 24px;
  }
  .social img {
    height: 40px;
  }
}

.bio-body {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 900px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
  padding: 8vh 3%;
}

.bio-body h1 {
  margin: 3% 2%;
}

.bio-body img {
  max-width: 60%;
  -o-object-fit: contain;
     object-fit: contain;
  padding-right: 1%;
  float: left;
  margin: 1%;
}

.bio-body p {
  font-size: 1.15rem;
  margin: 2% 0;
  text-align: justify;
  line-height: 1.5rem;
}

@media screen and (max-width: 600px) {
  .bio-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .bio-body h1 {
    margin: 3% auto 3% 0;
  }
  .bio-body img {
    float: none;
    max-width: 90%;
  }
}

.conciertos-body {
  padding: 8vh 2%;
  background-color: black;
  color: white;
  min-height: 100vh;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.conciertos-body h2 {
  width: 95%;
  max-width: 900px;
  margin: 40px auto;
}

.lista-conciertos {
  width: 95%;
  max-width: 900px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 20px;
}

.lista-conciertos .concierto {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.1rem;
  gap: 3%;
}

.lista-conciertos .concierto .fecha {
  font-weight: 100;
  width: 65px;
  min-width: 65px;
  font-size: .85rem;
}

.lista-conciertos .concierto .recinto {
  margin-left: 0;
  margin-right: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: left;
}

.lista-conciertos .concierto .ciudad {
  text-align: left;
  width: 85px;
  min-width: 85px;
}

@media screen and (max-width: 600px) {
  .recinto, .ciudad {
    font-size: .95rem;
  }
}

.docencia-body {
  width: 90%;
  max-width: 900px;
  min-height: 100vh;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
  padding: 8vh 0;
}

.docencia-body h1 {
  margin: 3% 2%;
}

.docencia-body .doce-photoA {
  max-width: 60%;
  max-height: 60vh;
  -o-object-fit: contain;
     object-fit: contain;
  padding-right: 1%;
  float: left;
  margin: 1%;
  display: inline;
}

.docencia-body p {
  font-size: 1.15rem;
  margin: 1%;
  text-align: justify;
  line-height: 1.5rem;
}

.docencia-body .doce-photoB {
  margin: 3% auto;
  width: 90%;
  margin-left: 5%;
}

@media screen and (max-width: 600px) {
  .docencia-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .docencia-body h1 {
    margin: 3% auto 3% 0;
  }
  .docencia-body img {
    float: none;
    max-width: 90%;
  }
}

.musica-body {
  min-height: 100vh;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  padding: 8vh 3%;
  background-color: black;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.button-panel {
  height: 8vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  max-width: 1000px;
}

.button-panel button {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: none;
  color: currentColor;
  border: none;
  font-size: 1.2rem;
  font-weight: 300;
}

.button-panel button.button-active {
  font-weight: 900;
}

.proyecto-body {
  width: 100%;
  max-width: 1000px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 8vh;
}

.proyecto-header {
  margin: 40px auto;
  max-width: 1000px;
}

.proyecto-header h1 {
  margin: 2% 0;
}

.proyecto-header img {
  max-width: 50%;
  float: left;
  padding: 1%;
  border: none;
}

.proyecto-header p {
  text-align: justify;
  margin: 1.5% 0;
  line-height: 1.2rem;
  margin-left: 0;
  margin-right: auto;
}

.links-proyecto {
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 1% 0;
  gap: 2%;
}

.links-proyecto a {
  height: inherit;
}

.links-proyecto a img {
  -webkit-filter: invert(100%) sepia(99%) saturate(18%) hue-rotate(240deg) brightness(103%) contrast(100%);
          filter: invert(100%) sepia(99%) saturate(18%) hue-rotate(240deg) brightness(103%) contrast(100%);
  -o-object-fit: contain;
     object-fit: contain;
  height: 80%;
}

.slider-proyecto {
  width: 90%;
  max-width: 1000px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 5% 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.slider-proyecto .swiper-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 45vh !important;
}

.slider-proyecto .swiper-wrapper .swiper-slide {
  height: 100%;
  width: 100%;
}

.slider-proyecto .swiper-wrapper .swiper-slide img {
  -o-object-fit: contain;
     object-fit: contain;
  height: 100%;
  width: 100%;
}

.disco-proyecto {
  width: 80% !important;
  max-width: 600px;
  max-height: 40vh;
  margin: 4% auto;
}

.partitura {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.partitura a {
  margin-left: 0;
  z-index: 3;
}

.partitura .react-pdf__Document {
  width: 95% !important;
}

.partitura .react-pdf__Document canvas {
  width: 100% !important;
  max-width: 500px;
  height: auto !important;
  margin: 0 auto;
}

.partitura .button-partitura {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 5vh;
  margin: 1% 0 5% 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 16px;
  z-index: 3;
}

.partitura .button-partitura button {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: none;
  color: white;
  border: none;
  font-size: 2rem;
}

@media screen and (max-width: 600px) {
  .proyecto-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .proyecto-header img {
    float: none;
  }
  .button-panel button {
    font-size: .95rem;
  }
}

.galeria-body {
  min-height: 100vh;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  padding: 8vh 0;
  background-color: black;
}

.imageGallery {
  width: 90%;
  max-width: 1000px;
  height: 55vh;
  margin: 3% 0;
}

.imageGallery .swiper-slide {
  height: 100%;
  width: 100%;
}

.imageGallery .swiper-slide img {
  -o-object-fit: contain;
     object-fit: contain;
  height: 100%;
  width: 100%;
}

.video-container {
  width: 90%;
  max-width: 700px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto 8vh auto;
}

.video-container .video-player {
  width: 100% !important;
}

.video-container p {
  color: white;
  margin: 1%;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.App {
  font-family: 'Manrope';
  position: relative;
}

.App a {
  text-decoration: none;
  color: currentColor;
}

footer {
  color: white;
  position: absolute;
  width: 100%;
  height: 4vh;
  padding: 0 3%;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
}

footer .dark {
  color: black;
}

footer a {
  text-decoration: none;
  color: currentColor;
}

@media screen and (max-width: 600px) {
  footer {
    font-size: .8rem;
  }
}
