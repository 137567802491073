@import "./fonts";
@import "./nav";
@import "./home";
@import "./bio";
@import "./conciertos";
@import "./docencia";
@import "./musica";
@import "./galeria";




* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.App{
    font-family: 'Manrope';
    position: relative;
    a{
        text-decoration: none;
        color: currentColor;
    }
    
}
footer{
    color: white;
    position:absolute;
    width: 100%;
    height: 4vh;
    padding: 0 3%;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    
    
    .dark{
        color: black;
      
        
    }
    a{
        text-decoration: none;
        color: currentColor;
    }
}

@media screen and (max-width: 600px) {
    footer{
        font-size: .8rem;
    }
}