.galeria-body{
    min-height: 100vh;
    height: fit-content;
    width: 100%;
    padding: 8vh 0;
    background-color: black;
    ;
}

.imageGallery{
    width: 90%;
    max-width: 1000px;
    height: 55vh;
    margin: 3% 0;
    .swiper-slide{
        height: 100%;
        width: 100%;
        img{
            object-fit: contain;
            height: 100%;
            width: 100%;
        }
    }
}
.video-container{
    width: 90% ;
    max-width: 700px;
    height: fit-content;
    margin: 0 auto 8vh auto;
    .video-player{
        width: 100% !important;
    }
    p{
        color: white;
        margin: 1%
    }
    
}
