.musica-body{
    min-height: 100vh;
    height: fit-content;
    width: 100%;
    padding: 8vh 3%;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.button-panel{
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    max-width: 1000px;
    button{
        height: fit-content;
        background: none;
        color: currentColor;
        border:none;
        font-size: 1.2rem;
        font-weight: 300;
        &.button-active{
            font-weight: 900;
            
        }
    }
}
.proyecto-body{
    width: 100%;
    max-width: 1000px;
    height: fit-content;
    padding-bottom: 8vh ;
}
.proyecto-header{
    margin: 40px auto;
    max-width: 1000px;
    h1{
        margin: 2% 0;
    }
    img{
        max-width: 50%;
        float: left;
        padding: 1%;
        border: none;
    }
    p {
        text-align: justify;
        margin: 1.5% 0;
        line-height: 1.2rem;
        margin-left: 0;
        margin-right: auto;
    }
}
.links-proyecto{
    height: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1% 0;
    gap: 2%;
    a {
        height: inherit;
        img{
            filter: invert(100%) sepia(99%) saturate(18%) hue-rotate(240deg) brightness(103%) contrast(100%);
            object-fit: contain;
            height: 80%;
        }
    }
}
.slider-proyecto{
    width: 90%;
    max-width: 1000px;
    height: fit-content;
    margin: 5% 0;
    display: flex;
    .swiper-wrapper{
        display: flex;
        align-items: center;
        height: 45vh !important;
        .swiper-slide{
            height: 100%;
            width: 100%;
            img{
                object-fit: contain;
                height: 100%;
                width: 100%;
            }
        }
    }
    
}
.disco-proyecto{
    width: 80% !important;
    max-width: 600px;
    max-height: 40vh;
    margin: 4% auto;
}

.partitura{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    a{
        margin-left: 0;
        z-index: 3;
    }
    
   .react-pdf__Document{
       width: 95% !important;
       canvas{
           width: 100% !important;
           max-width: 500px;
           height: auto !important;
           margin: 0 auto;
       }
   }
   
    
    .button-partitura{
        display: flex;
        height: 5vh;
        margin: 1% 0 5% 0;
        align-items: center;
        gap: 16px;
        z-index: 3;
        button{
            height: fit-content;
           
            background: none;
            color: white;
            border: none;
            font-size: 2rem;
        }

    }
    
}

@media screen and (max-width: 600px) { 

    .proyecto-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content;
        img {
            float: none;
        }
    }
    .button-panel button{
        font-size: .95rem;
    }

}