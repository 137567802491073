
.docencia-body {
   
    width: 90%;
    max-width: 900px;
    min-height: 100vh;
    height: fit-content;
    margin: 0 auto;
    padding: 8vh 0;
    
    h1{
        margin: 3% 2%;
    }
    .doce-photoA{
        max-width: 60%;
        max-height: 60vh;
        object-fit: contain;
        padding-right: 1%;
        float: left;
        margin: 1%;
        display: inline;
    }
    p{
        font-size: 1.15rem;
        margin: 1%;
        text-align: justify;
        line-height: 1.5rem; 
    }
    .doce-photoB{
        margin: 3% auto;
        width: 90%; 
        margin-left: 5%;
       
    }
}

@media screen and (max-width: 600px) { 

    .docencia-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        h1{
            margin: 3% auto 3% 0;
        }
        img{
        float: none;
        max-width: 90%;
        }
    }
}