
.conciertos-body{
    padding: 8vh 2%;
    background-color: black;
    color:white;
    min-height: 100vh;
    height: fit-content;
    h2{
        width: 95%;
        max-width: 900px;
        margin: 40px auto;
    }
}
.lista-conciertos {
    width: 95%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .concierto{
        display: flex;
        font-size: 1.1rem;
        
        gap: 3%;
        .fecha{
            font-weight: 100;
            
            width: 65px;
            min-width: 65px;
            font-size: .85rem;
        }
        .recinto{
            margin-left: 0;
            margin-right: auto;
            height: fit-content;
            text-align: left;
        }
        .ciudad{
            text-align: left;
            width: 85px;
            min-width: 85px;
        }
    }
}

@media screen and (max-width: 600px) {
    .recinto, .ciudad{
        font-size: .95rem;
    }
 }